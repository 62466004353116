import { Image, Spacer, Text, OL, LI } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Kell Effekt und Raster Interferenz
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Wenn die Abtastrate zu gering ist, dann kann man die Einzelnen Linien oder Spalten des
        Bildes erkennen. Es entsteht ein für das Auge sehr unangenehmer Effekt.
      </Text>

      <Spacer height={20} />

      <img src="/kell_factor.png" style={{ maxWidth: 400, margin: '0px auto' }} />

      <Spacer height={20} />

      <Text size={18} weight={600}>
        Es ist also nicht nur die Auflösung des Videos bzw. des Ausgabegerätes, sondern auch
        die Abtastrate des Bildsensors (der Kamera) für die wahrgenommene Qualität wichtig.
      </Text>
    </Wrapper>
  );
};

export let raml5: ISlide = {
  id: 'raml5',
  name: 'Kell Effekt und Raster Interferenz',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { Button, Error, Menu, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Datenersparnis mit Chroma Subsampling
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Da bei Chroma Subsampling die Farbwerte von nebeneinanderliegenden Pixel
        zusammengefasst werden, werden weniger Daten gespeichert. Damit lassen sich je nach Typ
        der Speicherverbrauch bzw. die benötigte Bandbreite um bis zu 50% reduzieren.
      </Text>

      <Spacer height={60} />

      <img src="/cr_size.svg" style={{ width: '100%' }} />
    </Wrapper>
  );
};

export let chrom6: ISlide = {
  id: 'chrom6',
  name: 'Datenersparnis mit Chroma Subsampling',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { LI, OL, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        YUV Farbraum
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Der YUV-Farbraum wird häufig bei Chroma Subsampling genutzt.
        <br />
        <br />
        Pixel haben im YUV Farbraum zwei Komponenten:
      </Text>

      <Spacer height={5} />

      <OL style={{ fontSize: 18 }}>
        <LI>Luminanz -&gt; Helligkeitswert</LI>

        <LI>Chrominanz -&gt; Farbe</LI>
      </OL>

      <Spacer height={20} />

      <Text size={18}>
        Die drei Werte können durch eine Formel in die passenden Rot-, Grün- und Blau-Wert
        umgewandelt werden.
        <br />
        <br />
        Beim Chroma Subsampling werden die Farbwerte zusammengefasst, die Helligkeiten aber
        nicht. Die einzelnen Pixel sind also noch unterscheidbar und es handelt sich nicht
        einfach um eine Verringerung der Auflösung.
      </Text>
    </Wrapper>
  );
};

export let chrom2: ISlide = {
  id: 'chrom2',
  name: 'YUV Farbraum',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { Image, Spacer, Text, OL, LI } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Angabe der Auflösung
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Für die Angabe der Auflösung eines Video gibt es im Wesentlichen zwei Möglichkeiten.
      </Text>

      <Spacer height={20} />

      <OL style={{ fontSize: 18 }}>
        <LI>Anzahl der Zeilen und Anzahl der Spalten</LI>
        <LI>Anzahl der Zeilen und das Seitenverhältnis</LI>
      </OL>

      <Spacer height={20} />

      <Text size={18}>
        Je höher die Auflösung ist, desto mehr Details sieht man. Ab einem bestimmten Wert sind
        keine einzelnen Linien oder Spalten mehr sichtbar -&gt; das Bild ist scharf.
      </Text>
    </Wrapper>
  );
};

export let raml4: ISlide = {
  id: 'raml4',
  name: 'Angabe der Auflösung',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { Image, Spacer, Text, OL, LI } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Halbbilder zusammensetzen
      </Text>

      <Spacer height={20} />

      <Text size={18}>Um Halbbilder zusammenzusetzen gibt es zwei Möglichkeiten.</Text>

      <Spacer height={5} />

      <OL style={{ fontSize: 18 }}>
        <LI>
          <strong>Interlaced Scanning</strong>: Es wird jeweils nur ein Halbbild angezeigt
        </LI>
        <LI>
          <strong>Progressive Scan / Zeilensprungverfahren</strong>: Es werden jeweils zwei
          Halbbilder zu einem zusammengesetzt.
        </LI>
      </OL>

      <Spacer height={20} />

      <img
        src="/interlace.gif"
        style={{ width: '600px', maxWidth: '100%', margin: '0px auto' }}
      />
    </Wrapper>
  );
};

export let raml8: ISlide = {
  id: 'raml8',
  name: 'Halbbilder zusammensetzen',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { keyframes, styled } from 'buttered';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import useDelayed from 'use-delayed';
import createGlobalState from 'use-state-global';
import { NavBar } from './components/navbar';
import { ISlide } from './interfaces/slide';
import { chrom1 } from './slides/chrom1';
import { chrom2 } from './slides/chrom2';
import { chrom2More } from './slides/chrom2_more';
import { chrom3 } from './slides/chrom3';
import { chrom4 } from './slides/chrom4';
import { chrom5 } from './slides/chrom5';
import { chrom6 } from './slides/chrom6';
import { finish } from './slides/finish';
import { raml1 } from './slides/raml1';
import { raml2 } from './slides/raml2';
import { raml3 } from './slides/raml3';
import { raml4 } from './slides/raml4';
import { raml5 } from './slides/raml5';
import { raml6 } from './slides/raml6';
import { raml7 } from './slides/raml7';
import { raml8 } from './slides/raml8';
import { thema1 } from './slides/thema1';
import { thema2 } from './slides/thema2';
import { welcomeSlide } from './slides/welcome';

let Wrapper = styled('div')``;

let fadeInLeft = keyframes`
  from {
    transform: translateX(-100px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
`;

let fadeInRight = keyframes`
  from {
    transform: translateX(100px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
`;

let fadeOutRight = keyframes`
  from {
    transform: translateX(0px);
    opacity: 1;
  }

  to {
    transform: translateX(100px);
    opacity: 0;
  }
`;

let fadeOutLeft = keyframes`
  from {
    transform: translateX(0px);
    opacity: 1;
  }

  to {
    transform: translateX(-100px);
    opacity: 0;
  }
`;

let SlideWrapper = styled('div')`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin-bottom: 80px;
  pointer-events: none;
  opacity: 0;
  overflow: auto;
  padding: 10px;

  display: flex;
  flex-direction: column;

  &.isCurrent {
    pointer-events: all;
  }
`;

let SlideAlign = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  flex-grow: 1;
`;

let Slide = ({
  slide,
  isCurrent,
  next,
  animation
}: {
  slide: ISlide;
  isCurrent?: boolean;
  animation: 'fadeInLeft' | 'fadeInRight' | 'fadeOutLeft' | 'fadeOutRight';
  next: () => void;
}) => {
  let animationStyle = `${fadeInLeft} 0.8s 0.6s forwards`;
  if (animation == 'fadeInRight') animationStyle = `${fadeInRight} 0.8s 0.6s forwards`;
  if (animation == 'fadeOutRight') animationStyle = `${fadeOutRight} 0.8s forwards`;
  if (animation == 'fadeOutLeft') animationStyle = `${fadeOutLeft} 0.8s forwards`;

  return (
    <SlideWrapper
      className={clsx({ isCurrent })}
      style={{
        animation: animationStyle
      }}
    >
      <SlideAlign>
        <div>{slide.component({ next, isCurrent: !!isCurrent })}</div>
      </SlideAlign>
    </SlideWrapper>
  );
};

let slides = [
  welcomeSlide,
  thema1,
  raml1,
  raml2,
  raml3,
  raml4,
  raml5,
  raml6,
  raml7,
  raml8,
  thema2,
  chrom1,
  chrom2,
  chrom2More,
  chrom3,
  chrom4,
  chrom5,
  chrom6,
  finish
];

let isLoaded = false;

let useIndex = createGlobalState(-1);

export let App = () => {
  let [index, setIndex] = useIndex();
  let delayedIndex = useDelayed(index, 500);
  let renderPrev = delayedIndex < index && index != 0;
  let renderNext = delayedIndex > index;

  let currentSlide = slides[index];
  let prevSlide = slides[index - 1];
  let nextSlide = slides[index + 1];

  let progress = index == 0 ? 0 : (index / slides.length) * 100 + 1;

  let next = () => {
    setIndex(index + 1);
  };

  let prev = () => {
    setIndex(index - 1);
  };

  useEffect(() => {
    if (isLoaded) return;

    setTimeout(() => {
      setIndex(0);
      isLoaded = true;
    }, 200);
  }, []);

  return (
    <Wrapper>
      {currentSlide && (
        <Slide
          next={next}
          slide={currentSlide}
          isCurrent
          animation="fadeInRight"
          key={currentSlide.id}
        />
      )}

      {prevSlide && renderPrev && (
        <Slide next={next} slide={prevSlide} animation="fadeOutLeft" key="prev" />
      )}

      {nextSlide && renderNext && (
        <Slide next={next} slide={nextSlide} animation="fadeOutRight" key="next" />
      )}

      <div
        style={{
          transition: 'all .8s',
          opacity: index >= 2 && index < slides.length - 1 ? 1 : 0
        }}
      >
        <NavBar name={currentSlide?.name} progress={progress} next={next} prev={prev} />
      </div>
    </Wrapper>
  );
};

import { Button, Error, Image, Menu, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import React, { useState } from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let QuizItem = styled('div')`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 40px;
  padding: 15px 0px;

  img {
    height: 160px;
  }
`;

let QuizOptions = styled('div')``;

let options: any = [
  {
    id: '16_9',
    type: 'item',
    component: '16:9'
  },
  {
    id: '4_3',
    type: 'item',
    component: '4:3'
  },
  {
    id: '185_1',
    type: 'item',
    component: '1,85:1'
  },
  {
    id: '239_1',
    type: 'item',
    component: '2,39:1'
  }
];

let getValue = (id?: string) => {
  let v = options.find((o: any) => o.id == id);
  if (v) return v.component;

  return 'Auswählen';
};

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  let [selected1, setSelected1] = useState<string>();
  let [selected2, setSelected2] = useState<string>();
  let [selected3, setSelected3] = useState<string>();
  let [selected4, setSelected4] = useState<string>();

  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Quiz zu Aspect Ratio
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Im Folgenden sind gängige Aspect Ratios aufgelistet. Ordnen Sie den Bildern das
        passende Seitenverhältnis zu.
      </Text>

      <Spacer height={30} />

      <QuizItem>
        <div>
          <img src="/ar/16_9.svg" />
        </div>

        <QuizOptions style={{ pointerEvents: selected1 ? 'none' : undefined }}>
          <VerticalCenter>
            <div>
              <Menu action={setSelected1} items={options} label="Options">
                {({ attrs, ref }) => (
                  <Button variant="primary" {...attrs} ref={ref}>
                    {getValue(selected1)}
                  </Button>
                )}
              </Menu>

              {selected1 && (
                <>
                  <Spacer height={10} />

                  {selected1 == '16_9' ? (
                    <Text color="var(--vapor-green)">Stimmt</Text>
                  ) : (
                    <Error>Leider Falsch. Es ist 16:9</Error>
                  )}
                </>
              )}
            </div>
          </VerticalCenter>
        </QuizOptions>
      </QuizItem>

      <QuizItem>
        <div>
          <img src="/ar/185_1.svg" />
        </div>

        <QuizOptions style={{ pointerEvents: selected2 ? 'none' : undefined }}>
          <VerticalCenter>
            <div>
              <Menu action={setSelected2} items={options} label="Options">
                {({ attrs, ref }) => (
                  <Button variant="primary" {...attrs} ref={ref}>
                    {getValue(selected2)}
                  </Button>
                )}
              </Menu>

              {selected2 && (
                <>
                  <Spacer height={10} />

                  {selected2 == '185_1' ? (
                    <Text color="var(--vapor-green)">Stimmt</Text>
                  ) : (
                    <Error>Leider Falsch. Es ist 1,85:1</Error>
                  )}
                </>
              )}
            </div>
          </VerticalCenter>
        </QuizOptions>
      </QuizItem>

      <QuizItem>
        <div>
          <img src="/ar/4_3.svg" />
        </div>

        <QuizOptions style={{ pointerEvents: selected3 ? 'none' : undefined }}>
          <VerticalCenter>
            <div>
              <Menu action={setSelected3} items={options} label="Options">
                {({ attrs, ref }) => (
                  <Button variant="primary" {...attrs} ref={ref}>
                    {getValue(selected3)}
                  </Button>
                )}
              </Menu>

              {selected3 && (
                <>
                  <Spacer height={10} />

                  {selected3 == '4_3' ? (
                    <Text color="var(--vapor-green)">Stimmt</Text>
                  ) : (
                    <Error>Leider Falsch. Es ist 4:3</Error>
                  )}
                </>
              )}
            </div>
          </VerticalCenter>
        </QuizOptions>
      </QuizItem>

      <QuizItem>
        <div>
          <img src="/ar/239_1.svg" />
        </div>

        <QuizOptions style={{ pointerEvents: selected4 ? 'none' : undefined }}>
          <VerticalCenter>
            <div>
              <Menu action={setSelected4} items={options} label="Options">
                {({ attrs, ref }) => (
                  <Button variant="primary" {...attrs} ref={ref}>
                    {getValue(selected4)}
                  </Button>
                )}
              </Menu>

              {selected4 && (
                <>
                  <Spacer height={10} />

                  {selected4 == '239_1' ? (
                    <Text color="var(--vapor-green)">Stimmt</Text>
                  ) : (
                    <Error>Leider Falsch. Es ist 239:1</Error>
                  )}
                </>
              )}
            </div>
          </VerticalCenter>
        </QuizOptions>
      </QuizItem>
    </Wrapper>
  );
};

export let raml3: ISlide = {
  id: 'raml3',
  name: 'Aspect Ratio',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import { useEffect } from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  text-align: center;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  useEffect(() => {
    if (!isCurrent) return;
    let to = setTimeout(() => {
      next();
    }, 3000);

    return () => clearTimeout(to);
  }, [isCurrent]);

  return (
    <Wrapper>
      <Text as="small" size={16} weight={700}>
        Thema 2
      </Text>
      <Spacer height={20} />
      <Text as="h1" size={40} weight={700}>
        Chroma Subsampling
      </Text>
    </Wrapper>
  );
};

export let thema2: ISlide = {
  id: 'thema2',
  name: '',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

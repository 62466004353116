import { LI, OL, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        YUV Farbraum
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Wenn man die drei YUV-Ebenen übereinander legt erhält man das gewünschte Bild.
      </Text>

      <Spacer height={20} />

      <img
        src="/yuv_demo.png"
        style={{ width: '550px', maxWidth: '100%', margin: '0px auto', display: 'block' }}
      />
    </Wrapper>
  );
};

export let chrom2More: ISlide = {
  id: 'chrom2More',
  name: 'YUV Farbraum',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Die Räumliche Auflösung
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Ein Video ist eine Serie von Bildern, welche kurz nacheinander abgespielt werden. Eine
        häufige Bilderwiederholrate ist 30 Bilder pro Sekunde.
        <br />
        <br />
        Die Räumliche Komponente beschreibt die horizontale und die vertikale Achse der
        einzelnen Bilder. Sie gibt also an wie viele Zeilen / Spalten es gibt.
      </Text>
    </Wrapper>
  );
};

export let raml1: ISlide = {
  id: 'raml1',
  name: 'Räumliche Ebene',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { Button, Error, LI, Menu, OL, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import React, { useState } from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let options: any = [
  {
    id: 'h',
    type: 'item',
    component: 'Helligkeit'
  },
  {
    id: 'f',
    type: 'item',
    component: 'Farbe'
  }
];

let getValue = (id?: string) => {
  let v = options.find((o: any) => o.id == id);
  if (v) return v.component;

  return 'Auswählen';
};

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  let [selected1, setSelected1] = useState<string>();
  let [selected2, setSelected2] = useState<string>();

  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Quiz zu YUV
      </Text>

      <Spacer height={20} />

      <Text size={18}>Was beschreibt Y im YUV-Farbraum?</Text>

      <Spacer height={10} />

      <div style={{ pointerEvents: selected1 ? 'none' : undefined }}>
        <Menu action={setSelected1} items={options} label="Options">
          {({ attrs, ref }) => (
            <Button variant="primary" {...attrs} ref={ref}>
              {getValue(selected1)}
            </Button>
          )}
        </Menu>

        {selected1 && (
          <>
            <Spacer height={10} />

            {selected1 == 'h' ? (
              <Text color="var(--vapor-green)">Stimmt</Text>
            ) : (
              <Error>Leider Falsch. Es ist Helligkeit.</Error>
            )}
          </>
        )}
      </div>

      <Spacer height={20} />

      <Text size={18}>Was beschreiben U und V im YUV-Farbraum?</Text>

      <Spacer height={10} />

      <div style={{ pointerEvents: selected2 ? 'none' : undefined }}>
        <Menu action={setSelected2} items={options} label="Options">
          {({ attrs, ref }) => (
            <Button variant="primary" {...attrs} ref={ref}>
              {getValue(selected2)}
            </Button>
          )}
        </Menu>

        {selected2 && (
          <>
            <Spacer height={10} />

            {selected2 == 'f' ? (
              <Text color="var(--vapor-green)">Stimmt</Text>
            ) : (
              <Error>Leider Falsch. Es ist Farbe.</Error>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export let chrom3: ISlide = {
  id: 'chrom3',
  name: 'YUV Farbraum',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { LI, OL, Spacer, Text, UL } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Angabe der Farbunterabtastung
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Die Farbunterabtastung wird als <span style={{ color: 'var(--vapor-yellow)' }}>A</span>
        :<span style={{ color: 'var(--vapor-blue)' }}>B</span>:
        <span style={{ color: 'var(--vapor-red)' }}>C</span> angegeben
      </Text>

      <Spacer height={5} />

      <UL style={{ fontSize: 18 }}>
        <LI>
          <span style={{ color: 'var(--vapor-yellow)' }}>A</span> gibt an wie viele Pixel
          horizontal betrachtet werden. Das sind in der Regel 4.
        </LI>

        <LI>
          <span style={{ color: 'var(--vapor-blue)' }}>B</span> gibt die Horizontale Abtastrate
          an. Also die Anzahl der Abtastungen im durch{' '}
          <span style={{ color: 'var(--vapor-yellow)' }}>A</span> definierten Bereich.
        </LI>

        <LI>
          <span style={{ color: 'var(--vapor-red)' }}>C</span> beschreibt dasselbe wie{' '}
          <span style={{ color: 'var(--vapor-blue)' }}>B</span>, aber für die untere Zeile des
          Blocks. (<span style={{ color: 'var(--vapor-red)' }}>C</span> ist in der Regel
          entweder 0 oder gleich <span style={{ color: 'var(--vapor-blue)' }}>B</span>)
        </LI>
      </UL>
    </Wrapper>
  );
};

export let chrom4: ISlide = {
  id: 'chrom4',
  name: 'Angabe der Farbunterabtastung',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

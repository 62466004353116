import { Button, Error, Menu, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import React, { useState } from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let QuizItem = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
  padding: 15px 0px;

  img {
    height: 160px;
  }
`;

let QuizOptions = styled('div')``;

let options: any = [
  {
    id: '411',
    type: 'item',
    component: '411'
  },
  {
    id: '420',
    type: 'item',
    component: '420'
  },
  {
    id: '422',
    type: 'item',
    component: '422'
  },
  {
    id: '444',
    type: 'item',
    component: '444'
  }
];

options = options.map((o: any) =>
  Object.assign(o, {
    component: o.component.split('').join(':')
  })
);

let getValue = (id?: string) => {
  let v = options.find((o: any) => o.id == id);
  if (v) return v.component;

  return 'Auswählen';
};

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  let [selected1, setSelected1] = useState<string>();
  let [selected2, setSelected2] = useState<string>();
  let [selected3, setSelected3] = useState<string>();
  let [selected4, setSelected4] = useState<string>();

  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Quiz zu Farbunterabtastung
      </Text>

      <Spacer height={20} />

      <QuizItem>
        <div>
          <img src="/cr/411.svg" />
        </div>

        <QuizOptions style={{ pointerEvents: selected1 ? 'none' : undefined }}>
          <VerticalCenter>
            <div>
              <Menu action={setSelected1} items={options} label="Options">
                {({ attrs, ref }) => (
                  <Button variant="primary" {...attrs} ref={ref}>
                    {getValue(selected1)}
                  </Button>
                )}
              </Menu>

              {selected1 && (
                <>
                  <Spacer height={10} />

                  {selected1 == '411' ? (
                    <Text color="var(--vapor-green)">Stimmt</Text>
                  ) : (
                    <Error>Leider Falsch. Es ist {getValue('411')}</Error>
                  )}
                </>
              )}
            </div>
          </VerticalCenter>
        </QuizOptions>
      </QuizItem>

      <QuizItem>
        <div>
          <img src="/cr/420.svg" />
        </div>

        <QuizOptions style={{ pointerEvents: selected2 ? 'none' : undefined }}>
          <VerticalCenter>
            <div>
              <Menu action={setSelected2} items={options} label="Options">
                {({ attrs, ref }) => (
                  <Button variant="primary" {...attrs} ref={ref}>
                    {getValue(selected2)}
                  </Button>
                )}
              </Menu>

              {selected2 && (
                <>
                  <Spacer height={10} />

                  {selected2 == '420' ? (
                    <Text color="var(--vapor-green)">Stimmt</Text>
                  ) : (
                    <Error>Leider Falsch. Es ist {getValue('420')}</Error>
                  )}
                </>
              )}
            </div>
          </VerticalCenter>
        </QuizOptions>
      </QuizItem>

      <QuizItem>
        <div>
          <img src="/cr/422.svg" />
        </div>

        <QuizOptions style={{ pointerEvents: selected3 ? 'none' : undefined }}>
          <VerticalCenter>
            <div>
              <Menu action={setSelected3} items={options} label="Options">
                {({ attrs, ref }) => (
                  <Button variant="primary" {...attrs} ref={ref}>
                    {getValue(selected3)}
                  </Button>
                )}
              </Menu>

              {selected3 && (
                <>
                  <Spacer height={10} />

                  {selected3 == '422' ? (
                    <Text color="var(--vapor-green)">Stimmt</Text>
                  ) : (
                    <Error>Leider Falsch. Es ist {getValue('422')}</Error>
                  )}
                </>
              )}
            </div>
          </VerticalCenter>
        </QuizOptions>
      </QuizItem>

      <QuizItem>
        <div>
          <img src="/cr/444.svg" />
        </div>

        <QuizOptions style={{ pointerEvents: selected4 ? 'none' : undefined }}>
          <VerticalCenter>
            <div>
              <Menu action={setSelected4} items={options} label="Options">
                {({ attrs, ref }) => (
                  <Button variant="primary" {...attrs} ref={ref}>
                    {getValue(selected4)}
                  </Button>
                )}
              </Menu>

              {selected4 && (
                <>
                  <Spacer height={10} />

                  {selected4 == '444' ? (
                    <Text color="var(--vapor-green)">Stimmt</Text>
                  ) : (
                    <Error>Leider Falsch. Es ist {getValue('444')}</Error>
                  )}
                </>
              )}
            </div>
          </VerticalCenter>
        </QuizOptions>
      </QuizItem>
    </Wrapper>
  );
};

export let chrom5: ISlide = {
  id: 'chrom5',
  name: 'Quiz zu Farbunterabtastung',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

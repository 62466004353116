import { Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import { useEffect } from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  text-align: center;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  useEffect(() => {
    if (!isCurrent) return;
    let to = setTimeout(() => {
      next();
    }, 4200);

    return () => clearTimeout(to);
  }, [isCurrent]);

  return (
    <Wrapper>
      <Text as="h1" size={40} weight={700}>
        MMS Lerneinheit
      </Text>
      <Spacer height="20px" />
      <Text as="p" size={20} weight={500}>
        Räumliche Auflösung und Chroma Subsampling
      </Text>
    </Wrapper>
  );
};

export let welcomeSlide: ISlide = {
  id: 'welcome',
  name: '',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { Image, Spacer, Text, OL, LI } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Vollbilder vs. Halbbilder
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Halbbilder sind mittlerweile fast ausgestorben. Dabei wird ein Video nicht nur in z.B.:
        30 bps sondern auch noch in jeweils zwei Halbbilder unterteilt. <br />
        <br />
        Dabei wird immer nur jede zweite Zeile gespeichert / übertragen was den
        Speicherverbrauch oder die Bandbreite deutlich reduziert.
      </Text>

      <Spacer height={20} />

      <img src="/h.gif" style={{ width: '600px', maxWidth: '100%', margin: '0px auto' }} />
    </Wrapper>
  );
};

export let raml7: ISlide = {
  id: 'raml7',
  name: 'Vollbilder vs. Halbbilder',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

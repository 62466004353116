import { Button, Spacer } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';

let Wrapper = styled('footer')`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-top: solid var(--accent-2) 1px;
  background: white;
`;

let ProgressWrapper = styled('div')`
  height: 8px;
  background: var(--accent-2);
`;

let Progress = styled('div')`
  background: var(--action-primary);
  display: flex;
  height: 100%;
  transition: all 0.3s;
`;

let Nav = styled('nav')`
  display: flex;
`;

let Inner = styled('div')`
  display: flex;
  padding: 15px 30px;
  flex-grow: 1;
`;

let Left = styled('div')`
  flex-grow: 1;
  display: flex;
`;

let Right = styled('div')`
  display: flex;
`;

let Name = styled('h3')`
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export let NavBar = ({
  progress,
  name,
  next,
  prev
}: {
  progress: number;
  name: string;
  next: () => void;
  prev: () => void;
}) => {
  return (
    <Wrapper>
      <Nav>
        <Inner>
          <Left>
            <Name>{name}</Name>
          </Left>

          <Right>
            <Button variant="tertiary" icon={<ChevronLeft />} onClick={prev}>
              Zurück
            </Button>
            <Spacer width={15} />
            <Button variant="primary" iconRight={<ChevronRight />} onClick={next}>
              Weiter
            </Button>
          </Right>
        </Inner>
      </Nav>

      <ProgressWrapper>
        <Progress style={{ width: progress + '%' }} />
      </ProgressWrapper>
    </Wrapper>
  );
};

import { Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Chroma Subsampling
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Beim Chroma Subsampling werden die Farbwerte von Nebeneinanderliegenden Pixeln
        zusammengefasst. Dadurch kommt es zu einer Reduktion der Datenmenge.
        <br />
        <br />
        Eine wichtige Voraussetzung ist dafür, dass die Farbwerte nicht wie traditionell üblich
        als Rot-, Grün- und Blau-Wert gespeichert werden, sondern als Luminanz (Helligkeit) und
        Chrominanz (Farbe).
      </Text>

      <Spacer height={60} />

      <img
        src="/yuv2.svg"
        style={{ maxWidth: '100%', width: 300, margin: '0px auto', display: 'block' }}
      />
    </Wrapper>
  );
};

export let chrom1: ISlide = {
  id: 'chrom1',
  name: 'Chroma Subsampling',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

import { Button, Error, Image, Menu, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import React, { useState } from 'react';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let QuizItem = styled('div')`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 40px;
  padding: 15px 0px;

  img {
    height: 160px;
  }
`;

let QuizOptions = styled('div')``;

let options: any = [
  {
    id: 'ok_Bearbeitung',
    type: 'item',
    component: 'Bearbeitung'
  },
  {
    id: 'ok_CGI und 3D Effekte',
    type: 'item',
    component: 'CGI und 3D Effekte'
  },
  {
    id: 'ok_Zukunftssicherheit',
    type: 'item',
    component: 'Zukunftssicherheit'
  },
  {
    id: 'Weil',
    type: 'item',
    component: 'Weil es geht'
  },
  {
    id: 'Effizientere Speicherung',
    type: 'item',
    component: 'Effizientere Speicherung'
  },
  {
    id: 'Mehr Daten = Besser',
    type: 'item',
    component: 'Mehr Daten = Besser'
  }
];

let getValue = (id?: string) => {
  let v = options.find((o: any) => o.id == id);
  if (v) return v.component;

  return 'Auswählen';
};

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  let [selected, setSelected] = useState<string>();

  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Warum werden viele Filme heute bereits in 8K (8192 × 4320) oder 12K (11520 x 6480)
        aufgenommen, obwohl es kaum Bildschirme mit einer Auflösung über 4K gibt?
      </Text>

      <Spacer height={20} />

      <div style={{ pointerEvents: selected ? 'none' : undefined }}>
        <Menu action={setSelected} items={options} label="Options">
          {({ attrs, ref }) => (
            <Button variant="primary" {...attrs} ref={ref}>
              {getValue(selected)}
            </Button>
          )}
        </Menu>

        {selected && (
          <>
            <Spacer height={10} />

            {selected.startsWith('ok_') ? (
              <Text color="var(--vapor-green)">Stimmt</Text>
            ) : (
              <Error>
                Leider Falsch. Bearbeitung, CGI oder Zukunftssicherheit wären richtig gewesen.
              </Error>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export let raml6: ISlide = {
  id: 'raml6',
  name: 'Quiz',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

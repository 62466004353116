import { Image, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Aspect Ratio
      </Text>

      <Text as="h3" size={15} weight={600}>
        Seitenverhältnis
      </Text>

      <Spacer height={20} />

      <Text size={18}>
        Aspect Ratio ist das Seitenverhältnis der <u>vertikalen und horizontalen Achse</u>.
        Dabei steht jeweils der erste Wert für die Breite und der zweite Wert für die Höhe.
      </Text>

      <Spacer height={40} />

      <img src="/16_9.svg" style={{ width: '400px', maxWidth: '100%', margin: '0px auto' }} />
    </Wrapper>
  );
};

export let raml2: ISlide = {
  id: 'raml2',
  name: 'Aspect Ratio',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};

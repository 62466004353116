import { Button, Error, Menu, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import { ISlide } from '../interfaces/slide';

let Wrapper = styled('div')`
  max-width: 720px;
`;

let Component = ({ next, isCurrent }: { next: () => void; isCurrent: boolean }) => {
  return (
    <Wrapper>
      <Text as="h1" size={24} weight={600}>
        Vielen Dank für die Aufmerksamkeit!
      </Text>

      <Spacer height={25} />

      <Text size={18} weight={600}>
        Entwickelt von Tobias Herber.
      </Text>

      <Spacer height={25} />

      <Text size={18} weight={600}>
        <a href="/quellen.pdf">Quellen</a>
      </Text>
    </Wrapper>
  );
};

export let finish: ISlide = {
  id: 'finish',
  name: '',
  component: ({ next, isCurrent }) => <Component next={next} isCurrent={isCurrent} />
};
